
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const NekoPage = props => {
	return (
		<CaseStudyPage link="neko" title="Brand &amp; Branding, Comunicazione - Neko"
		relPath="neko" description="Brand, Brandigng e Comunicazione per Neko. Un'osposi tra oriente e sud del mondo.">
		</CaseStudyPage>
	);
};

export default NekoPage;
